import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const theme = createTheme();

export const typography: TypographyOptions = {
  
    fontFamily: '"Quicksand", sans-serif',
    button: {
      textTransform: "none",
      fontWeight: "medium",
    },
    h1: {
      fontFamily: '"Merienda", cursive',
      fontSize: "30px",
      lineHeight: 1.2
    },
    h2: {
      letterSpacing: 1.2,
      fontSize: "24px",
      '@media (max-width:400px)': {
        fontSize: '17px',
      },
      lineHeight: 1.2
    },
    h3: {
      fontSize: "18px",
      lineHeight: 1.2
    },
    h4: {
      fontSize: "18px",
      lineHeight: 1.2
    },
    h5: {
      fontSize: "13px",
      lineHeight: 1.2
    },
    h6: {
      fontSize: "10px",
      lineHeight: 1.2
    },
    body1: {
      fontSize: "18px",
      lineHeight: 1.2
    },
    body2: {
      fontSize: "16px",
      lineHeight: 1.8
    },
    quote:{
      fontFamily: '"Amita", cursive',
      fontSize: "17px",
      lineHeight: 1.9,
      letterSpacing: 1.2
    },
    caption:{
      lineHeight: 1.1,
      '@media (max-width:400px)': {
        fontSize: '10px',
        lineHeight: 1,
        p: 0
      },
    }
};
