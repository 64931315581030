import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { palette } from "./palette";
import { typography } from "./typography";

type AlternatePalette = {
  eggShell: string;
};

declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    alternate: AlternatePalette;
  }
  interface Palette {
    alternate: AlternatePalette;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    quote: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    quote?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    quote: true;
  }
}

const getTheme = () =>
  responsiveFontSizes(
    createTheme({
      palette,
      typography,
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              color:"black",
              borderRadius: 7,
              backgroundColor: "white"
            },
            input: {
              borderRadius: 5,
            },
          },
        },
        MuiCheckbox:{
          styleOverrides: {
            root : {
              "&.Mui-checked": {
                color: '#66635d'
              }
            },
            
          }
        },
        MuiButton: {
          styleOverrides: {
            contained: {
              color: '#66635d', 
              fontWeight: 600,
              backgroundColor: '#D6CCC2',
              '&:hover': { transform: 'scale3d(1.05, 1.05, 1)',  fontWeight: 600,  backgroundColor: '#D6CCC2' }
            },
            outlined: {
              color: '#66635d',
              fontWeight: 700,
              borderRadius: 7,
              px: 5,
              py: 0.5,
              borderWidth: '3px',
              borderColor: '#D6CCC2',
              '&:hover': { backgroundColor: '#D6CCC2', borderWidth: '3px', borderColor: '#D6CCC2' }
            },
            text: {
              color: '#66635d',
              textDecoration: 'underline',
              fontSize: '16px',
              '&:hover': { transform: 'scale3d(1.05, 1.05, 1)',  background: "none" }

            }
          },
        },
        MuiAlert: {
          styleOverrides: {
            filledSuccess: {
              backgroundColor: '#D6CCC2',
              color: '#66635d'
            }
          }
        }
      },
    })
  );

export default getTheme;
