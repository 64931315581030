import React from 'react';

const NotFoundView = React.lazy(() => import('./views/NotFoundView'));
const HomepageView = React.lazy(() => import('./views/HomepageView'));
const WhoAmIView = React.lazy(() => import('./views/WhoAmIView'));
const PsychotherapiesView = React.lazy(() => import('./views/PsychotherapiesView'));
const CabinetView = React.lazy(() => import('./views/CabinetView'));
const PraticalInformationView = React.lazy(() => import('./views/PraticalInformationView'));
const ContactView = React.lazy(() => import('./views/ContactView'));
const LegalNoticeView = React.lazy(() => import('./views/LegalNoticeView'));


const routes = [
  {
    path: '/',
    element: <HomepageView />,
  },
  {
    path: '/presentation',
    element: <WhoAmIView />,
  },
  {
    path: '/psychotherapies-proposees',
    element: <PsychotherapiesView />,
  },
  {
    path: '/le-cabinet',
    element: <CabinetView />,
  },
  {
    path: '/informations-pratiques',
    element: <PraticalInformationView />,
  },
  {
    path: '/contact',
    element: <ContactView />,
  },
  {
    path: '/mentions-legales',
    element: <LegalNoticeView />,
  },
  {
    path: '*',
    element: <NotFoundView />,
  },
];

export default routes;