import { PaletteOptions } from "@mui/material";

export const palette: PaletteOptions = {
  alternate: {
    eggShell : "#FDF7F7"
  },
  common: {
    black: "#000",
    white: "#fff",
  },
  mode: "light",
  primary: {
    main: "#D5BDAF", // 1 : nothing
    light: "#fbf7f3", // 2 Page background
  },
  secondary: {
    main: "#807c75", // 4: dark brown -> nothing
    light: "#D6CCC2", // 3 : Footer
  },
  divider: "#D6CCC2",
  background: {
    paper: "#f9f9f9",
    default: "#f9f9f9",
  },
  text: {
    primary: '#66635d',
  },
};
