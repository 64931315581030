import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { StyledEngineProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "./theme";
import React from "react";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const routing = useRoutes(routes);
  return (
    <StyledEngineProvider injectFirst>
      <HelmetProvider>
        <ThemeProvider theme={getTheme()}>
          <CssBaseline />
          {routing}
        </ThemeProvider>
      </HelmetProvider>
    </StyledEngineProvider>
  );
};

export default App;
